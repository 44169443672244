<template>
    <div class="list-view">
        <page-title></page-title>
        <div class="filters-container">
            <el-input placeholder="输入关键字" v-model="search" clearable class="filter-item"></el-input>
            <el-button @click="initData" type="primary" icon="el-icon-search" class="filter-item">搜索 </el-button>
            <el-button @click="addRow" type="primary" icon="el-icon-plus" class="filter-item">添加 </el-button>
            <el-button
                @click="download"
                type="primary"
                icon="el-icon-download"
                :loading="downloading"
                class="filter-item"
                >导出EXCEL
            </el-button>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="异常名称"> </el-table-column>
            <el-table-column prop="type" label="操作类型"> </el-table-column>
            <el-table-column prop="userId" label="用户ID"> </el-table-column>
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="desc" label="描述"> </el-table-column>
            <el-table-column prop="reqMethod" label="调用方法"> </el-table-column>
            <el-table-column prop="reqUrl" label="请求地址"> </el-table-column>
            <el-table-column prop="reqParams" label="请求参数"> </el-table-column>
            <el-table-column prop="reqIp" label="请求IP"> </el-table-column>
            <el-table-column prop="resp" label="返回结果"> </el-table-column>
            <el-table-column prop="time" label="操作时间"> </el-table-column>
            <el-table-column prop="message" label="异常消息"> </el-table-column>
            <el-table-column prop="stackTrace" label="错误追踪"> </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" min-width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
        <el-dialog :visible.sync="showResp" title="返回结果">
            <pre
                >{{ resp }}
           </pre
            >
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'ExceptionLogList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/exceptionLog/all',
            downloading: false,
            sortStr: '',
            resp: '',
            showResp: false
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            if (this.search) {
                return { search: this.search };
            }
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/exceptionLogEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/exceptionLogEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/exceptionLog/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/exceptionLog/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        },
        viewResp(row) {
            try {
                this.resp = JSON.stringify(JSON.parse(row.resp), null, 4);
            } catch (e) {
                console.log(e);
                this.resp = row.resp;
            }
            this.showResp = true;
        }
    }
};
</script>
<style lang="less" scoped></style>
